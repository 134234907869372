import React from 'react';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import Header from './components/Header';
import Home from './components/Home';
import Projects from './components/Projects';
import Tools from './components/Tools';
import FileDrop from './components/FileDrop';
import Contact from './components/Contact';
import CursorEffect from './components/CursorEffect';

const AppContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const ContentContainer = styled.main`
  padding: 20px 0;
`;

function App() {
    return (
        <>
        <CursorEffect/>
        <AppContainer>
            <Header />
            <ContentContainer>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/tools" element={<Tools />} />
                    <Route path="/filedrop" element={<FileDrop />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
            </ContentContainer>
        </AppContainer>
        </>
    );
}

export default App;
