import React, { useState } from 'react';
import styled from 'styled-components';
import { Card, CardTitle, CardContent } from './SharedStyles';

const ProjectsContainer = styled.div`
    display: flex;
    height: calc(100vh - 100px); // Adjust based on your header height
`;

const Sidebar = styled.div`
    width: 300px;
    border-right: ${props => props.isHovered ? '1px solid #ccc' : 'none'};
    padding: 20px;
    transition: border-right 0.3s ease;
`;

const ProjectList = styled.ul`
    list-style-type: none;
    padding: 0;
`;

const ProjectItem = styled.li`
    cursor: pointer;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #f0f0f0;
    }
`;

const ProjectContent = styled.div`
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
`;

const ProjectCard = styled(Card)`
    width: 100%;
`;

const ProjectImage = styled.img`
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
`;

const ProjectLink = styled.a`
    display: inline-block;
    margin-top: 20px;
    margin-right: 10px;
    padding: 10px 15px;
    background-color: white;
    color: #333;
    text-decoration: none;
    border-radius: 4px;
    border: 1px solid #ccc;
    transition: all 0.3s ease;

    &:hover {
        background-color: #f0f0f0;
        transform: translateY(-2px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &:active {
        transform: translateY(0);
        box-shadow: none;
    }
`;

const PopupOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const PopupContent = styled.div`
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
`;

const PopupIframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
`;

const Projects = () => {
    const [selectedProject, setSelectedProject] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const projects = [
        {
            id: 'moviesearch',
            name: 'MovieSearch Site',
            description: `A comprehensive web application for searching and discovering movies. This project showcases my proficiency in modern web development technologies and practices:

            - Developed using React for the frontend, demonstrating my ability to create dynamic and responsive user interfaces.
            - Implemented a Node.js backend, highlighting my full-stack development capabilities.
            - Integrated multiple external APIs, including ChatGPT for enhanced search functionality and OMDb for comprehensive movie data, showcasing my skills in working with third-party services.
            - Implemented user authentication and personalized watchlists, demonstrating database management skills.
            - Collaborated in a team environment, utilizing Git for version control and practicing agile methodologies.
            - Deployed the application on AWS, gaining experience with cloud platform services.

            This project not only enhanced my technical skills but also improved my ability to work in a team, manage project timelines, and solve complex problems collaboratively.`,
            image: '/api/placeholder/400/200',
            github: 'https://github.com/yourusername/moviesearch',
            website: 'http://moovies.click'  // Updated to HTTPS
        },
        {
            id: 'aroundu',
            name: 'AroundU App',
            description: 'A mobile app for discovering local events and activities.',
            image: '/api/placeholder/400/200',
            github: 'https://github.com/yourusername/aroundu',
            website: 'https://aroundu-demo.com'
        },
        {
            id: 'shortestpath',
            name: 'ShortestPath Algo',
            description: 'An implementation of various shortest path algorithms.',
            image: '/api/placeholder/400/200',
            github: 'https://github.com/yourusername/shortestpath',
        }
    ];

    const handleOpenDemo = (e) => {
        e.preventDefault();
        setShowPopup(true);
    };

    return (
        <ProjectsContainer>
            <Sidebar
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                isHovered={isHovered}
            >
                <ProjectList>
                    {projects.map(project => (
                        <ProjectItem key={project.id} onClick={() => setSelectedProject(project)}>
                            {project.name}
                        </ProjectItem>
                    ))}
                </ProjectList>
            </Sidebar>
            <ProjectContent>
                {selectedProject ? (
                    <ProjectCard>
                        <CardTitle>{selectedProject.name}</CardTitle>
                        <CardContent>
                            <p>{selectedProject.description.split('\n').map((point, index) => (
                                <p key={index}>{point}</p>
                            ))}</p>
                            <ProjectLink href={selectedProject.github} target="_blank" rel="noopener noreferrer">
                                GitHub Repository
                            </ProjectLink>
                            {selectedProject.website && (
                                <ProjectLink href="#" onClick={handleOpenDemo}>
                                    Open Live Demo
                                </ProjectLink>
                            )}
                        </CardContent>
                    </ProjectCard>
                ) : (
                    <p>Select a project from the sidebar to view details.</p>
                )}
            </ProjectContent>
            {showPopup && selectedProject && (
                <PopupOverlay>
                    <PopupContent>
                        <CloseButton onClick={() => setShowPopup(false)}>&times;</CloseButton>
                        <PopupIframe src={selectedProject.website} title={selectedProject.name} sandbox="allow-scripts allow-same-origin"/>
                    </PopupContent>
                </PopupOverlay>
            )}
        </ProjectsContainer>
    );
};

export default Projects;