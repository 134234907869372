import styled from 'styled-components';

export const Card = styled.div`
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  backdrop-filter: blur(5px);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

export const CardTitle = styled.h2`
  font-size: 1.5em;
  margin-bottom: 10px;
`;

export const CardContent = styled.div`
  font-size: 1em;
`;