import React from 'react';
import styled from 'styled-components';
import { Card, CardTitle, CardContent } from './SharedStyles';

const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
`;

const Name = styled.h1`
    font-size: 2.5em;
    margin-bottom: 10px;
`;

const Contact = styled.p`
    font-size: 1.2em;
    margin-bottom: 20px;
`;

const CardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
`;

const HobbyContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 40px;
`;

const HobbyCard = styled(Card)`
    text-align: center;
    width: 200px;
`;

const HobbyImage = styled.img`
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
`;

const Home = () => {
    return (
        <HomeContainer>
            <Name>Kaining Gao</Name>
            <Contact>Des Moines, IA | 641 780 5653 | gaokaining22@gmail.com | <a href="https://github.com/KainingGao" target="_blank" rel="noopener noreferrer">Github: KainingGao</a></Contact>

            <CardContainer>
                <Card>
                    <CardTitle>Education</CardTitle>
                    <CardContent>
                        <p><strong>Iowa State University, Computer Science</strong></p>
                        <p>Second-Year Student (2022-2026)</p>
                        <p>GPA: 3.80</p>
                        <p>Table Tennis Varsity</p>
                        <p><strong>Pella Christian High School</strong></p>
                        <p>Graduated (2018-2022)</p>
                    </CardContent>
                </Card>

                <Card>
                    <CardTitle>Courses Taken</CardTitle>
                    <CardContent>
                        <ul>
                            <li>Data Structure and Algorithm (Java)</li>
                            <li>Object Oriented Programming (Java)</li>
                            <li>Intro to Programming (Python)</li>
                            <li>Computational Structure (Discrete Math)</li>
                            <li>Design and Analysis of Algorithms</li>
                            <li>User Interface (HTML, JS, CSS)</li>
                            <li>Database Management (SQL)</li>
                            <li>Vermeer Winterim for high school students (IT)</li>
                        </ul>
                    </CardContent>
                </Card>

                <Card>
                    <CardTitle>Work Experience</CardTitle>
                    <CardContent>
                        <p><strong>Summit Products | Construction Site Translator</strong> (03/2023)</p>
                        <ul>
                            <li>Translated verbally in a factory setting interchangeably with Chinese and English.</li>
                            <li>Delivered factory-level communications and tactical directions.</li>
                            <li>Gained hands-on familiarity with factory operations and equipment.</li>
                        </ul>
                        <p><strong>S.V.E. International Education Training Center | English Teacher's Assistant</strong> (07/2019 - 08/2019)</p>
                        <ul>
                            <li>Assisted in the preparation of classroom and instruction materials.</li>
                            <li>Mentored and tutored individual students needing additional help.</li>
                        </ul>
                        <p><strong>McDonald's | Crew Member</strong> (05/2022 – 07/2022)</p>
                        <ul>
                            <li>Provided high-quality customer service in a fast-paced environment.</li>
                            <li>Accurately took orders and operated the POS system with efficiency.</li>
                        </ul>
                    </CardContent>
                </Card>

                <Card>
                    <CardTitle>Skills & Abilities</CardTitle>
                    <CardContent>
                        <ul>
                            <li>Teamwork & Communication</li>
                            <li>Problem-solving</li>
                            <li>Adaptability</li>
                            <li>Attention to Detail</li>
                        </ul>
                    </CardContent>
                </Card>
            </CardContainer>

            <HobbyContainer>
                <HobbyCard>
                    <HobbyImage src="/api/placeholder/150/150" alt="Table Tennis" />
                    <CardTitle>Table Tennis</CardTitle>
                </HobbyCard>
                <HobbyCard>
                    <HobbyImage src="/api/placeholder/150/150" alt="Golf" />
                    <CardTitle>Golf</CardTitle>
                </HobbyCard>
            </HobbyContainer>
        </HomeContainer>
    );
};

export default Home;