import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid rgba(238, 238, 238, 0.5);
    background-color: rgba(255, 255, 255, 0.5); // 50% transparent white
    backdrop-filter: blur(5px); // This adds a slight blur effect
`;

const Logo = styled.h1`
    margin: 0;
    font-size: 24px;
`;

const Nav = styled.nav`
    display: flex;
    gap: 20px;
`;

const NavLink = styled(Link)`
    text-decoration: none;
    color: #333;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 5px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.1);
        transform: translateY(100%);
        transition: transform 0.3s ease;
        z-index: -1;
    }

    &:hover {
        color: #007bff;
        transform: translateY(-3px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        &:before {
            transform: translateY(0);
        }
    }

    &:active {
        transform: translateY(-1px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
`;

function Header() {
    return (
        <HeaderContainer>
            <Logo>kngao.com</Logo>
            <Nav>
                <NavLink to="/">Home</NavLink>
                <NavLink to="/projects">Projects</NavLink>
                <NavLink to="/tools">Tools</NavLink>
                <NavLink to="/filedrop">FileDrop</NavLink>
                <NavLink to="/contact">Contact</NavLink>
            </Nav>
        </HeaderContainer>
    );
}

export default Header;